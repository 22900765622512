import { render, staticRenderFns } from "./merchants.vue?vue&type=template&id=2c8610f0&scoped=true&"
import script from "./merchants.vue?vue&type=script&lang=js&"
export * from "./merchants.vue?vue&type=script&lang=js&"
import style0 from "./merchants.vue?vue&type=style&index=0&id=2c8610f0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8610f0",
  null
  
)

export default component.exports